<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/about">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.aboutBusiness") }}</li>
      </ul>
    </div>
    <div class="main main_page bg-fff">
      <div class="about_text">
        <div v-if="locale==='en'" class="box">
          Terms
          <br />
          Welcome to {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!
          <br />
          The technology and services provided by
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} are designed to help
          people interact, use their free time to make money, provide a platform
          for help, and develop business. These terms of service apply to your
          use of {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} and other
          products, functions, applications, services, technologies and software
          (collectively referred to as
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} or products) provided
          by us, unless we clearly stipulate that other terms (not the terms of
          this article) apply. These products are provided by
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}.
          <br />
          Your use of {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} or other
          products and services described in this article is free. By using our
          products, you agree to what we can show you.
          <br />
          We keep your personal information confidential and will not sell your
          personal data to others.
          <br />
          <br />
          1. Services we provide
          <br />
          Our mission is to create more jobs, let many people get rich, and help
          poor people improve their lives. We provide you with products and
          services:
          <br />
          Research ways to improve our services:
          <br />
          We will participate in research activities to develop, test and
          improve our products. This includes analyzing the user data we collect
          and understanding how users use our products, such as conducting
          surveys, testing and troubleshooting new features. The data usage
          policy explains how we use data to support research to develop and
          improve our services.
          <br />
          To provide users with our services on a global scale:
          <br />
          To operate global services, we need to store and distribute content
          and data in data centers and systems around the world (including
          outside the country/region where you live). This infrastructure may be
          operated or controlled by
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} Limited or its
          affiliates.
          <br />
          <br />
          2. Your commitment to {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}
          <br />
          We provide these services to you and others to help advance our
          mission. In exchange, we need you to make the following commitments:
          <br />
          1. Who can use {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}
          <br />
          When people carry out activities with their real identities, Nigeria
          Order will be safer and people will be more responsible for their
          actions. Therefore, we require every user:
          <br />
          Use names from everyday life.
          <br />
          Provide accurate information about yourself.
          <br />
          Only create an account (for personal use), and only use your timeline
          for personal purposes.
          <br />
          Do not disclose your account password to others, do not allow others
          to use your own
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} account, and do not
          transfer your own account to others without our permission.
          <br />
          We try our best to make
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} available to everyone,
          but you must not use
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} in the following
          situations:
          <br />
          1. Under 13 years old (or under the minimum legal age for using our
          products in your country/region).
          <br />
          2. You are a convicted sex offender
          <br />
          3. Your account has been disabled by us for violating our terms or
          policies
          <br />
          <br />
          We have the right to restrict users who violate these regulations from
          using the APP or restrict their access. If you seriously or repeatedly
          violate the terms of this article; taking the foregoing actions may
          expose us or other parties to legal liabilities, may damage Nigeria
          Order users, may damage or affect the integrity or operation of any
          services, systems or products we provide; Restricted by technical
          limitations; or the law prohibits us from taking the aforementioned
          actions.
          <br />
          2. Permission granted to us by you
          <br />
          We need you to grant us specific permissions to provide services to
          you:
          <br />
          1. Investigate or determine illegal activities or violations of our
          terms and policies (for example: determine or investigate violations
          of our products or systems);
          <br />
          2. Fulfill legal obligations, such as retaining evidence;
          <br />
          3. Comply with the requirements of judicial or administrative
          departments, law enforcement departments or government agencies;
          <br />
          <br />
          1. In this case, the retention period of the relevant content will not
          exceed the necessary retention period determined according to the
          purpose of content retention, and the exact retention period depends
          on the situation.
          <br />
          2. Update the permissions of the software you use or download: You
          download or use our software, which means granting us a license to
          allow us to download and install the update for you when the software
          update is available.
          <br />
          <br />
          3. Additional terms
          <br />
          1. Account freeze or suspension
          <br />
          We hope that {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} is a
          place where people feel safe. If we determine that you have violated
          our terms or policies clearly, severely, or repeatedly, we will
          temporarily suspend or permanently disable your account.
          <br />
          2. Limitation of Liability
          <br />
          We try our best to provide users with the best products and provide
          clear guidelines for each user. However, we only provide products as
          they are, so we do not guarantee that the products will continue to be
          safe, reliable, and error-free, nor that the operation of the products
          will not be interrupted, delayed or imperfect. To the extent permitted
          by law, we also refuse to acknowledge all express or implied
          warranties, including implied warranties of merchantability,
          suitability for specific purposes, ownership and non-infringement. We
          cannot predict when there will be problems with our products.
          Accordingly, our liability shall be limited to the maximum extent
          permitted by applicable laws; for any loss of profit, income,
          information or data arising from or related to this clause or Nigeria
          Order products, or consequential, special, indirect, disciplinary
          Sexual, punitive or collateral damages, even if we have been notified
          that such damages may occur, we will not be liable.
          <br />
          3. Disputes
          <br />
          We try to provide clear rules to reduce or try to avoid disputes
          between you and us. However, if a dispute does occur, it is helpful to
          know in advance where to resolve the dispute and the applicable law.
          If you are a consumer, the laws of your country of residence will
          apply to any claims, litigation or disputes (collectively referred to
          as “claims”) brought against us in relation to these terms or Nigeria
          Order products, and you may have jurisdiction over these claims Any
          competent court in the country/region of jurisdiction seeks a
          solution. In all other cases, you agree that these claims will only be
          resolved in the U.S. District Court for the Northern District of
          California or the state courts in San Mateo County. You also agree
          that your litigation regarding any of these claims is subject to the
          personal jurisdiction of the aforementioned courts, and that
          California law will apply to these terms and any claims, regardless of
          conflict of laws principles.
          <br />
          4. Other
          <br />
          1. This clause (formerly known as the "Statement of Rights and
          Liability") constitutes the complete agreement between you and Nigeria
          Order regarding the use of our products. This clause supersedes any
          previous agreement.
          <br />
          2. Some products we provide are also subject to supplementary terms.
          If you use any of these products, we will provide supplementary terms
          and they will be part of the agreement between us and you. For
          example, if you use our products for commercial or business purposes,
          such as buying advertisements, selling products, developing
          applications, managing your company's management team or public
          homepage, or using our effectiveness measurement service, you must
          agree to our business terms. If you publish or share content with
          music, you must comply with our music usage guidelines. If any
          supplementary terms conflict with these terms, the supplementary terms
          shall prevail with regard to the conflicting terms.
          <br />
          3. If any part of this clause is deemed unenforceable, the validity of
          the remaining parts will not be affected. Our failure to implement any
          part of these terms should not be considered as a waiver of our
          rights. Any modification or waiver of these terms must be in writing
          and signed by us.
          <br />
          4. Without our consent, you may not transfer any rights or obligations
          under these terms to any third party.
          <br />
          5. You can designate a person (referred to as a "delegated contact")
          to manage your account when it becomes a mourning account. Only your
          entrusted contact person or the person you have explicitly agreed in a
          valid will or similar document to disclose your content to them after
          death or incapacity can request the disclosure of account content to
          them after your account becomes a mourning account.
          <br />
          This clause does not grant any third-party beneficiary rights. We have
          the right to freely transfer all our rights and obligations under
          these terms due to mergers, acquisitions, asset sales or enforcement
          of legal or other reasons.
          <br />
          Device Information
          <br />
          As described below, we collect information from computers, mobile
          phones, smart TVs, and other networked devices that you use and
          integrate our products, and information about these devices, and merge
          this information on the different devices you use. For example, we use
          the collected information about your use of our products on your
          mobile phone to better customize the content (including
          advertisements) that you can see when you use our products on other
          devices such as laptops or tablets. Function, or to evaluate whether
          you will respond to and take action on the ads you see on your phone
          on other devices.
          <br />
          The information we obtain from these devices includes:
          <br />
          Device attributes: such as operating system, hardware and software
          version, battery power, signal strength, available storage space,
          browser type, application and file name and type, and plug-in
          information.
          <br />
          Device operation: Information about the operations and behaviors
          performed by the user on the device, such as whether the window is set
          as the foreground or the background, or the movement of the mouse
          (this helps distinguish between manual operations and machine
          operations).
          <br />
          Identification information: unique identifiers, device numbers, and
          other identification information (such as information obtained from
          the games you play, applications or accounts you use), and home device
          numbers (or other information related to
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}’s Unique
          identification information for the product).
          <br />
          Device signal: Bluetooth signal and information about surrounding
          Wi-Fi access points, beacons and cell phone towers.
          <br />
          Information from device settings: Information that you allow us to
          receive through the device settings you turn on, such as access to
          your GPS location, camera, or photos.
          <br />
          Network and connection: For example, information such as your mobile
          operator or ISP name, language, time zone, mobile phone number, IP
          address, connection speed, etc., and in some cases, information about
          other devices on or near your network, so that We are able to perform
          various operations, such as helping you to cast videos from your phone
          to your TV.
          <br />
          Cookie data: Data obtained from cookies stored on your device,
          including cookie ID and settings. Users can learn more about how we
          use cookies in the
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} Cookie Policy.
          <br />
          Location-related information: We use location-related information
          (such as your current location, where you live, where you want to go,
          and businesses and people near you) to provide, customize, and improve
          our products to you and others ( Including advertising).
          Location-related information may be based on: accurate device location
          information (if you allow us to collect it), IP address, and
          information about you and others using
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} products (such as
          signing in or participating in events).
          <br />
          Product research and development: We use the information we have to
          develop, test and improve our products, including conducting surveys
          and research, testing and troubleshooting new products and functions.
          <br />
          Provide effectiveness measurement, analysis and other business
          services.
          <br />
          We use the information we have (including your activities outside of
          our products, such as websites and advertisements browsed) to help
          advertisers and other partners evaluate the effectiveness and coverage
          of their advertisements and services, and to understand the people who
          use their services Types and how people interact with their websites,
          apps, and services.
          <br />
          Strengthen user security, data security and product reputation.
          <br />
          We use the information we have to verify accounts and activities,
          combat harmful behavior, detect and prevent spam and other bad
          experiences, maintain the credibility of our products, and enhance
          user safety and data security inside and outside
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}
          products. For example, we use the data we have to investigate
          suspicious activity or violations of our terms or policies, or to
          detect when users need help.
          <br />
          Communicate with you.
          <br />
          We will use the information we have to send you marketing information,
          communicate with you about our products, and let you understand our
          policies and terms. When you contact us, we will also use your
          information to provide a response.
          <br />
          If this policy changes, how will we notify you?
          <br />
          We will notify you before revising this policy so that you can view
          the revised policy before choosing to continue using our products.
          <br />
        </div>

        <div v-if="locale==='pb'" class="box">
          Termos

          <br />

          Bem-vindos a {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}!

          <br />

          A tecnologia e os serviços fornecidos por
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} são projetados para
          ajudar as pessoas a interagir, usar seu tempo livre para ganhar
          dinheiro, fornecer plataforma para ajuda, e desenvolver negócios.
          Esses termos de serviço se aplicam para seu uso de
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} e outros produtos,
          funções, aplicações, serviços, tecnologias e software (coletivamente
          chamado de {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} ou
          produtos) fornecidos por nós, a menos que clearly stipulate that other
          terms (not the terms of this article) aplicar. Esses produtos são
          fornecidos por {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}.

          <br />

          Seu uso de {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} ou outros
          produtos e serviços descritos em este artigo é gratuito. By using our
          products, you agree to what we can mostrar.

          <br />

          Manteremos sua informação pessoal confidencial e não venderemos sua
          dados pessoais para outros.

          <br />

          <br />

          1. Serviços que fornecemos

          <br />

          Nossa missão é criar mais empregos, deixar muitas pessoas ficarem
          ricos, e ajudar as pessoas pobres melhoram suas vidas. Nós lhe
          fornecemos produtos e serviços:

          <br />

          Modos de pesquisa para melhorar nossos serviços:

          <br />

          Nós participaremos em atividades de pesquisa para desenvolver, testar
          e melhorar nossos produtos. Isto inclui analisar os dados de usuário
          que coletamos e entender como os usuários usam nossos produtos, como
          conduzir pesquisas, testes e problematizantes novas características. O
          uso de dados a política explica como usamos dados para apoiar pesquisa
          para desenvolver e melhorar nossos serviços.

          <br />

          Para fornecer aos usuários nossos serviços em escala global:

          <br />

          Para operar serviços globais, precisamos armazenar e distribuir
          conteúdo e dados em centros de dados e sistemas em todo o mundo
          (incluindo fora do país/região onde você vive). Essa infraestrutura
          pode ser operado ou controlado por
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} Limited or its
          affiliates.

          <br />

          <br />

          2. Seu compromisso com {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}

          <br />

          We provide these services to you and others to help advance our
          missão. Em troca, precisamos que assumam os seguintes compromissos:

          <br />

          1. Who can use {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}

          <br />

          When people carry out activities with their real identities, Nigeria A
          ordem será mais segura e as pessoas serão mais responsáveis por sua
          ações. Portanto, precisamos de cada usuário:

          <br />

          Usa nomes da vida cotidiana.

          <br />

          Fornecer informações precisas sobre si mesmo.

          <br />

          Só criar uma conta (para uso pessoal), e apenas usar sua linha de
          tempo para fins pessoais.

          <br />

          Não divulgue a senha de sua conta a outros, não permita que outros
          para usar seu próprio
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} conta, e não transfera
          seu próprio contam com outros sem nossa autorização.

          <br />

          Tentamos o nosso melhor para fazer
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} disponíveis para
          todos, mas você não devem usar
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} in the following
          situations:

          <br />

          1. Menos de 13 anos (ou menor da idade legal mínima para usar produtos
          em seu país/região).

          <br />

          2. Você é um criminoso de sexo condenado

          <br />

          3. Sua conta foi desactivada por nós por violar nossos termos ou
          políticas

          <br />

          <br />

          Temos o direito de restringir os usuários que violam esses
          regulamentos usando o APP ou restringindo seu acesso. Se você
          seriamente ou repetidamente violam os termos deste artigo; tomando as
          ações anteriores pode nos expõem ou outras partes a responsabilidades
          legais, podem prejudicar a Nigéria Order users, may damage or affect
          the integrity or operation of any serviços, sistemas ou produtos que
          fornecemos; Restricida por técnicos limitations; ou a lei nos proibe
          de tomar o acima mencionado ações.

          <br />

          2. Permissão concedida a nós por vocês

          <br />

          Precisamos que nos concedam permissões específicas para fornecer
          serviços você:

          <br />

          1. Investigar ou determinar atividades ilegais ou violações de nosso
          termos e políticas (por exemplo: determinar ou investigar violações de
          nossos produtos ou sistemas);

          <br />

          2. cumprir obrigações legais, como manter evidências;

          <br />

          3. Cumpre com os requisitos judiciais ou administrativos
          departamentos, departamentos de aplicação da lei ou agências
          governamentais;

          <br />

          <br />
          1. Neste caso, o período de retenção do conteúdo relevante não irá
          ultrapassam o período de retenção necessário determinado de acordo
          purpose of content retention, and the exact retention period depends
          sobre a situação.

          <br />

          2. Actualiza os permissões do software que você usa ou descarrega:
          Você descarregar ou usar nosso software, o que significa nos conceder
          uma licença nos permitem descarregar e instalar a atualização para
          vocês quando o software atualização está disponível.

          <br />

          <br />

          3. Termos adicionais

          <br />

          1. Contas congeladas ou suspensas

          <br />

          Esperamos que {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} é um
          lugar onde as pessoas se sentem seguras. Se nós determinam que vocês
          violaram nossos termos ou políticas claramente, severamente, ou
          repetidamente, suspenderemos temporariamente ou permanentemente
          desactivar sua conta.

          <br />

          2. Limitação da responsabilidade

          <br />

          Tentamos o nosso melhor para fornecer aos usuários os melhores
          produtos e fornecer diretrizes claras para cada usuário. No entanto,
          nós apenas fornecemos produtos como eles são, então não garantimos que
          os produtos continuarão seguro, confiável e sem erros, nem que o
          funcionamento dos produtos não serão interrompidos, atrasados ou
          imperfeitos. To the extent permitted pela lei, também nos recusamos a
          reconhecer tudo expresso ou implicado garantias, incluindo garantias
          implicadas de comerciabilidade, adequação para fins específicos,
          propriedade e não infracção. Nós não pode prever quando haverá
          problemas com nossos produtos. Por conseguinte, nossa responsabilidade
          será limitada ao máximo permitidos pelas leis aplicáveis; por qualquer
          perda de lucro, renda, informações ou dados oriundos ou relacionados
          com essa cláusula ou Nigéria Order products, or consequential,
          special, indirect, disciplinary Danos sexuais, punitivos ou
          colaterais, mesmo que tenhamos sido notificados que esses danos podem
          ocorrer, nós não seremos responsáveis.

          <br />

          3. Disputes

          <br />

          Tentamos proporcionar regras claras para reduzir ou evitar disputas
          entre você e nós. No entanto, se uma disputa acontecer, é útil sabem
          antecipadamente onde resolver a disputa e a lei aplicável. Se você é
          um consumidor, as leis do seu país de residência se aplicam a
          quaisquer pretensões, litígios ou disputas (coletivamente referidos
          como "alegações") trazidas contra nós em relação a esses termos ou a
          Nigéria Ordem produtos, e você pode ter jurisdição sobre essas
          alegações Qualquer o tribunal competente no país/região de jurisdição
          busca solução. Em todos os outros casos, você concorda que essas
          alegações só serão resolvido no Tribunal de Distrito dos EUA para o
          Distrito Norte de California or the state courts in San Mateo County.
          Você também concorda que seu litigio em relação a qualquer destas
          alegações está sujeito jurisdição pessoal dos tribunais acima
          mencionados, e que A lei da Califórnia se aplicará a esses termos e
          quaisquer pretensões, independentemente princípios de conflito de
          leis.

          <br />

          4. Outros

          <br />

          1. Esta cláusula (anteriormente conhecida como "Declaração de Direitos
          e Liability") constitutes the complete agreement between you and
          Nigeria Ordem em relação ao uso de nossos produtos. Esta cláusula
          substitui qualquer acordo anterior.

          <br />

          2. Alguns produtos que fornecemos também são sujeitos a termos
          suplementares. Se você usa qualquer desses produtos, nós forneceremos
          termos suplementares e elas serão parte do acordo entre nós e vocês.
          Para exemplo, se você usa nossos produtos para fins comerciais ou de
          negócios, como comprar anúncios, vender produtos, desenvolver
          aplicações, gerenciando a equipe de gestão de sua empresa ou público
          página inicial, ou usando nosso serviço de mensuração da eficácia,
          você deve concordam com nossos termos de negócio. Se você publicar ou
          compartilhar conteúdo com música, você deve cumprir nossas diretrizes
          de uso da música. Se alguma supplementary terms conflict with these
          terms, the supplementary terms prevalecerão em relação aos termos em
          conflito.

          <br />
          3. Se qualquer parte desta cláusula for considerada inenforcável, a
          validade de as partes restantes não serão afetadas. Nosso fracasso em
          implementar qualquer parte desses termos não deveria ser considerada
          como uma renúncia a nossa direitos. Qualquer modificação ou renúncia
          desses termos deve ser escrita e assinado por nós.

          <br />

          4. Sem nosso consentimento, você não pode transferir nenhum direito ou
          obrigação nesses termos a qualquer terceiro.

          <br />

          5. Você pode designar uma pessoa (chamada de "contato delegado") para
          gerenciar sua conta quando se torna uma conta de luto. Only your a
          pessoa de contato confiada ou a pessoa que você explicitamente
          concordou em vontade válida ou documento semelhante para revelar seu
          conteúdo a eles depois morte ou incapacidade pode pedir a divulgação
          do conteúdo da conta para depois que sua conta se torna uma conta de
          luto.

          <br />

          Esta cláusula não concede direitos de beneficiários de terceiros. Nós
          temos o direito de transferir livremente todos os nossos direitos e
          obrigações these terms due to mergers, acquisitions, asset sales or
          enforcement de razões legais ou outras.

          <br />

          Informação do dispositivo

          <br />

          Como descrito abaixo, coletamos informações de computadores, móveis
          telefones, televisões inteligentes e outros dispositivos de rede que
          você usa e integrar nossos produtos, e informação sobre esses
          dispositivos, e fusionar essa informação sobre os diferentes
          dispositivos que você usa. Por exemplo, usamos a informação coletada
          sobre seu uso de nossos produtos em seu o celular para melhor
          personalizar o conteúdo (incluindo anúncios) que vocês podem ver
          quando usam nossos produtos em outros dispositivos como laptops ou
          tabletas. Função, ou avaliar se você responderá e tomará ação nos
          anúncios que vê no seu telefone em outros dispositivos.

          <br />

          A informação que obtemos desses dispositivos inclui:

          <br />

          Atributos do dispositivo: como sistema operacional, hardware e
          software version, battery power, signal strength, available storage
          space, tipo de navegador, aplicação e nome e tipo de ficheiro, e
          plug-in informação.

          <br />

          Operação de dispositivo: Informação sobre as operações e
          comportamentos realizada pelo usuário no dispositivo, como se a janela
          está configurada como o primeiro plano ou o fundo, ou o movimento do
          rato (isso ajuda a distinguir entre operações manuales e máquinas
          operations).

          <br />

          Informação de identificação: identificadores únicos, números de
          dispositivo e outras informações de identificação (como informações
          obtidas de os jogos que você joga, aplicações ou contas que você usa),
          e o dispositivo doméstico números (ou outra informação relacionada a
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}’ s Unico informação de
          identificação do produto).

          <br />

          sinal de dispositivo: sinal Bluetooth e informação sobre o ambiente
          pontos de acesso Wi-Fi, sinais e torres de celulares.

          <br />

          Informação dos dispositivos: Informação que você nos permite recebem
          através das configurações do dispositivo que você liga, como acesso a
          sua localização GPS, câmera ou fotos.

          <br />

          Rede e conexão: Por exemplo, informações como seu móvel nome de
          operador ou ISP, linguagem, zona horaria, número de telefone móvel, IP
          endereço, velocidade de conexão, etc., e em alguns casos, informação
          sobre outros dispositivos em ou perto de sua rede, para que possamos
          desempenhar various operations, such as helping you to cast videos
          from your phone para sua TV.

          <br />

          Dados de cookies: Dados obtidos de cookies armazenados em seu
          dispositivo, incluindo identificação e configuração de cookies. Os
          usuários podem aprender mais sobre como nós usam cookies no
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} Política de Cookie.

          <br />
          Informação relacionada ao local: Usamos informação relacionada ao
          local (such as your current location, where you live, where you want
          to go, e empresas e pessoas próximas de você) para fornecer,
          personalizar e melhorar nossos produtos para vocês e outros (
          incluindo publicidade). Informação relacionada à localização pode ser
          baseada em: localização precisa do dispositivo informação (se você nos
          permitir coletá-la), endereço IP, e informação sobre você e outros
          usando {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} produtos (como
          assinando ou participando em eventos).

          <br />

          Pesquisa e desenvolvimento de produtos: Usamos a informação que
          precisamos desenvolver, testar e melhorar nossos produtos, incluindo
          realizar pesquisas e pesquisa, testes e problematização de novos
          produtos e funções.

          <br />

          Fornecer medidas de eficácia, análise e outros negócios serviços.

          <br />

          Usamos a informação que temos (incluindo suas atividades fora nossos
          produtos, como websites e anúncios navegados) para ajudar anunciadores
          e outros parceiros avaliam a eficácia e cobertura de seus anúncios e
          serviços, e entender as pessoas que usam seus serviços Tipos e como as
          pessoas interagem com seus websites, aplicações e serviços.

          <br />

          Reforçar a segurança dos usuários, a segurança dos dados e a reputação
          dos produtos.

          <br />

          Usamos a informação que temos para verificar contas e atividades,
          combater comportamentos nocivos, detectar e prevenir spam e outros
          maus experiências, manter a credibilidade de nossos produtos, e
          melhorar user safety and data security inside and outside
          {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}

          produtos. Por exemplo, usamos os dados que temos que investigar
          atividade suspeita ou violações de nossos termos ou políticas, ou
          detectar quando os usuários precisam de ajuda.

          <br />

          Comuniquem com vocês.

          <br />

          Usaremos a informação que temos para enviar informações de marketing,
          comunicar com vocês sobre nossos produtos, e deixar vocês entenderem
          nossos políticas e termos. Quando você nos contactar, nós também
          usaremos seu informação para proporcionar uma resposta.

          <br />

          Se essa política muda, como vamos avisá-lo?

          <br />

          Nós vamos avisá-los antes de revisar essa política para que vocês
          possam ver the revised policy before choosing to continue using our
          products.

          <br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import StaticImage from '@/components/StaticImage.vue';
import { useI18n } from "@/lang";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  // components: { StaticImage },
  setup() {
    const { t,locale } = useI18n();
    return {
      t,
      locale,
      sysDataDictionaryWbObj,
    };
  },
};
</script>
